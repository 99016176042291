<template>
    <div style="margin-bottom:2rem;">
        <div class="flex wrap">
            <div class="col3" style="height:500px;" @click="go" v-for="(item,index) in data" :key="index">
                <Item :data="item" ></Item>
            </div>

        </div>
        <More></More>
    </div>
</template>
<script>
import Item from "@/components/columnItem.vue"
import More from "@/components/More.vue"
export default {
    components:{Item,More},
    data(){
        return{
            data:[
                {url:'case/1-1.png',title:'明明没有喝酒，意外身故后尸身却检测出乙醇，怎么回事呢？',time:'2018-07-24',content:`柯某驾驶的重型特殊结构货车在某市大道的灯杆路段中，与王某驾驶的两轮普通摩托车发生交通事故，造成车损及王某当场死亡的损害后果。当地警方特委托福`},
                
                ]
        }
    },
    methods:{
        go(){
            let pathStr = '/case/legal_case/1'
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>